<template>
<div>
  <!-- Modal Trigger -->
  <a class="waves-effect waves-light btn modal-trigger" href="#modal1" @click="toggle">Modal</a>



  <!-- Modal Structure -->
  <div id="modal1" class="modal viewed" :style="`${isVisible? 'visibility: visible; top: 15%;' : 'opacity: 0'}`" @blur="toggle">
    <div class="modal-content">
      <h4>Modal Header</h4>
      <p>A bunch of text</p>
    </div>
    <div class="modal-footer">
      <a href="#!" class="modal-close waves-effect waves-green btn-flat">Agree</a>
    </div>
  </div>

</div>
</template>

<script>
export default {
name: "Modal",
  data() {
    return {
      isVisible: false
    }
  },
  methods: {
    toggle(){
      this.isVisible = !this.isVisible
    }
  }
}
</script>

<style scoped>
.viewed {
  display: inherit !important;
  visibility: hidden;
  opacity: 1;
  top: 20%;
  /*left: 0;*/
  z-index: 999;
  transition: 0.2s;
}

</style>