import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// Materialize
import 'materialize-css'
import 'materialize-css/dist/css/materialize.min.css'
import 'material-design-icons/iconfont/material-icons.css'
// Firebase
import firebase from 'firebase/app'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyB6kmwkG2nhiEM8RKa4s4LWjCUf7deVc3s",
  // authDomain: "dicto-682d4.firebaseapp.com",
  authDomain: "learnsyrian.site",
  projectId: "dicto-682d4",
  storageBucket: "dicto-682d4.appspot.com",
  messagingSenderId: "351049446054",
  appId: "1:351049446054:web:2a2b37410e86b963e0ba67",
  measurementId: "G-HWYZKG7QX1"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
