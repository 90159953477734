<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <div class="btn" @click="testingMethod">Click me</div>
    <router-link to="/profile">profile</router-link>
<!--    <HelloWorld msg="Welcome to Your Vue.js App" />-->
    <Modal></Modal>
    ({{$route.query.love}})
  </div>
</template>

<script>
// @ is an alias to /src
import Modal from "../components/Modal";

export default {
  name: 'Home',
  components: {
     Modal
  },
  methods: {
    testingMethod() {
      this.$store.dispatch("TestingAction")
    }
  },
  mounted() {
    console.log(this.$route.query.test)
  }
}
</script>
