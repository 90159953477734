import auth from "./auth";


function validateEmail(email){
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
}


// functions to save / retrieve a certain state/variable
function saveState(state,  key, value){
    window.localStorage.setItem(key, JSON.stringify(value))
    state.storage[key] = value
}

function getState(key){
    const value = window.localStorage.getItem(key)
    if(value == null) return null
    else return JSON.parse(value)
}

function removeState(state,  key){
    state.storage[key] = null
    return window.localStorage.removeItem(key)
}

function clearState(){
    window.localStorage.clear()
}


function routerOnlyAuthorized(to, from, next){
    if(auth.state.user){
        next();
    }
    else{
        next(false);
    }
}

function routerOnlyUnauthorized(to, from, next){
    if(auth.state.user){
        next(false);
    }
    else{
        next();
    }
}

export default {
    validateEmail,
    saveState,
    getState,
    removeState,
    clearState,
    routerOnlyAuthorized,
    routerOnlyUnauthorized
}