<template>
<div>
  <!-- Dropdown Title -->
  <a :class='cclass' href='#' :data-target='id' @click="toggle" @blur="isOpen = false" style="display: inline-flex"><!--i class="material-icons">person</i-->  {{text}}</a>
  <!-- Dropdown Menu -->
  <div style="position: relative">
    <ul :id='id' class='mus-dropdown-content card'
        :style="`display: flow !important;
        visibility: ${isOpen? 'visible' : 'hidden'};
         opacity: ${isOpen? '1' : '0'};
         transform: rotate(${isOpen? '0' : '5'}deg);
          top: ${isOpen? '0' : '-1'}em`">

<!--        Items as in <li>< href="#"> Item </a>></li>>-->

          <slot></slot>
    </ul>
  </div>

</div>
</template>

<script>



export default {
  name: "Dropdown",
  props: {
    cclass: {
      type: String,
      default() {
        return 'dropdown-trigger'
      }
    },
    id: {
      type: String,
      required: true
    },
    text: {
      type: String,
      default() {
        return 'Drop me if you want!'
      }
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    }
  },
  mounted() {
    // document.addEventListener('DOMContentLoaded', () => {
    //   const elements = document.querySelectorAll('.dropdown-trigger')
    //   M.Dropdown.init(elements, {alignment: 'left', constrainWidth: false, coverTrigger: true})
    // })
  },
}


</script>

<style scoped>
.mus-dropdown-content {
  background-color: #fff;
  margin: 0;
  /*display: flow-root;*/
  min-width: 100px;
  width: 200%;
  overflow-y: auto;
  /*opacity: 0;*/
  position: absolute;
  left: -101%;
  /*top: 0;*/
  z-index: 9999;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  transform: rotate(-20deg);
  transition: 0.15s;
}

.mus-dropdown-content li {
  clear: both;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  min-height: 50px;
  line-height: 1.5rem;
  width: 100%;
  text-align: left;
}


.mus-dropdown-content li:hover, .mus-dropdown-content li.active {
  background-color: #eee;
}

.mus-dropdown-content li:focus {
  outline: none;
}

.mus-dropdown-content li.divider {
  min-height: 0;
  height: 1px;
}


.mus-dropdown-content li > a, .mus-dropdown-content li > span {
  font-size: 16px;
  color: #26a69a;
  display: block;
  line-height: 22px;
  padding: 14px 16px;
}


.mus-dropdown-content li > .router-link, .mus-dropdown-content li > span {
  font-size: 16px;
  color: #26a69a;
  display: block;
  line-height: 22px;
  padding: 14px 16px;
}

.mus-dropdown-content li > span > label {
  top: 1px;
  left: 0;
  height: 18px;
}

.mus-dropdown-content li > a > i {
  height: inherit;
  line-height: inherit;
  float: left;
  margin: 0 24px 0 0;
  width: 24px;
}

.mus-dropdown-content li > .router-link > i {
  height: inherit;
  line-height: inherit;
  float: left;
  margin: 0 24px 0 0;
  width: 24px;
}

body.keyboard-focused .mus-dropdown-content li:focus {
  background-color: #dadada;
}

.input-field.col .mus-dropdown-content [type="checkbox"] + label {
  top: 1px;
  left: 0;
  height: 18px;
  -webkit-transform: none;
  transform: none;
}
</style>