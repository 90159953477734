import utils from "./utils";
// import router from "@router";
import firebase from "firebase/app";
import auth from "./auth";
// import axios from "axios"
import store from "../index"
import "firebase/firestore"

const state = {
    storage: {
        profile: utils.getState("profile"),
    },
    cacheMsg: {
        error: false,
        msg: ""
    },
    verifiedMessage: {
        error: true,
        msg: ""
    },
}

const getters = {

}

const actions = {
    async fetchProfile({commit, state}){
        await store.dispatch("startLoading")
        if(auth.state.storage.user && !state.storage.profile){
            await firebase.firestore().collection("users").doc(auth.state.storage.user.uid).get()
                .then(
                    (querySnapshot) => {
                        commit('AUTH_RETRIEVE_PROFILE', querySnapshot.data())
                    })
                .catch(() => {
                    console.log("couldn't fetch profile")
                    commit("PROFILE_MSG", {msg: "Couldn't load the profile", error: true})
                    // Sorry but the profile couldn't be retrieved
                })
        }
        await store.dispatch("finishLoading")
    },


    async updateProfile({commit, state}, changes){
        // clear cache Message
        commit("PROFILE_MSG", {msg: "", error: false})
        await store.dispatch("startLoading")


        // changes in user
        if(changes.displayName  && firebase.auth().currentUser && changes.displayName !== firebase.auth().currentUser.displayName){
            // get the current User
            await firebase.auth().currentUser.updateProfile({displayName: changes.displayName})
                .then(() => {
                    console.log(firebase.auth().currentUser.displayName)
                    commit("AUTH_UPDATE_USER", firebase.auth().currentUser)
                    commit("PROFILE_MSG", {msg: "updated successfully!", error: false})
                })
                .catch((error) => {
                    commit("PROFILE_MSG", {msg: error.code, error: true})
                })
        }


        // changes in profile
        if( (changes.age || changes.sex) && firebase.auth().currentUser && state.storage.profile ) {
            if(changes.age !== state.storage.profile.age || changes.sex !== state.storage.profile.sex){
                // update the current User
                const _applyChanges = {
                    birthYear: (new Date()).getFullYear() - changes.age,
                    sex: changes.sex
                }
                await firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).update(_applyChanges)
                    .then(() => {
                        commit("UPDATE_PROFILE", changes)
                        commit("PROFILE_MSG", {msg: "updated successfully!", error: false})
                    })
                    .catch((error) => {
                        commit("PROFILE_MSG", {msg: error.code, error: true})
                    })
            }
        }
        await store.dispatch("finishLoading")
    },


    async TestingAction(){
        console.log(firebase.auth().currentUser)
        if (firebase.auth().currentUser){
            await firebase.auth().currentUser.sendEmailVerification()
            // verifyBeforeUpdateEmail(auth.state.storage.user.email)
        }
    },

    async checkVerification({commit}){
        commit("CHECK_VERIFICATION")
    },

    async resendVerification({commit}){
        await firebase.auth().currentUser.sendEmailVerification().then(()=> {
            commit("VERIFICATION_SENT")
        })
    }

}



const mutations = {
    VERIFICATION_SENT(state){
        state.verifiedMessage = {msg: "Verification sent!", error: true}
    },
    CHECK_VERIFICATION(state){
        state.verifiedMessage = {msg: "", error: false}
        if(auth.state.storage.user.emailVerified){
            state.verifiedMessage = {msg: "", error: false}
        }
        else
        {
            state.verifiedMessage = {msg: "You haven't verified your email yet!", error: true}
        }
    },
    AUTH_RETRIEVE_PROFILE(state, profile){
        utils.saveState(state, "profile", profile)
        if(!auth.state.storage.user.emailVerified){
            state.verifiedMessage = {msg: "You haven't verified your email yet!", error: true}
        }
    },

    UPDATE_PROFILE(state, changes){
        let profile = state.storage.profile
        if(changes.age) {
            profile.age = changes.age
        }
        if(changes.sex) {
            profile.sex = changes.sex
        }
        utils.saveState(state, "profile", profile)
    },
    PROFILE_MSG(state, msg){
        state.cacheMsg = msg;
    }
}






export default {
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations
}