import utils from "./utils";
import router from "@router";
import auth from "./auth";
import store from "../index"
import firebase from "firebase";
import axios from "axios"
import "firebase/firestore"

const state = {

}

const getters = {

}

const actions = {
    authGoogleLogin({commit}){
        store.dispatch("startLoading")
        var provider = new firebase.auth.GoogleAuthProvider();
        // optional OAutho2
        provider.addScope("https://www.googleapis.com/auth/userinfo.profile")
        // Optional local
        firebase.auth().signInWithPopup(provider)
            .then(async (userCredential) => {
                const credential = userCredential.credential
                const token = credential.accessToken
                // Get first and lastname from Google API
                const Userinfo = await axios.get("https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=" + token)
                // Information received directly
                const user = userCredential.user

                const userProfile = await firebase.firestore().collection("users").doc(user.uid).get()

                // New user
                if(!userProfile.exists){
                    await firebase.firestore().collection("users").doc(user.uid).set({
                        firstname: Userinfo.data.given_name,
                        lastname: Userinfo.data.family_name,
                        email: user.email,
                        birthYear: (new Date()).getFullYear(),
                        sex: "unspecified",
                        profileCreatedOn: Date.now(),
                        picture: user.photoURL,
                        token: token,
                        isVerified: true,
                    })
                }

                commit("AUTH_GOOGLE_LOGIN", user)
                await store.dispatch("finishLoading")
            })
            .catch(() => {
                 store.dispatch("finishLoading")
                // Couldn't sign in with Google account
            })
    }
}



const mutations = {
    // AUTH_MSG(state, msg){
    //     console.log(msg)
    //     state.cacheMsg = msg;
    // },
    AUTH_GOOGLE_LOGIN(state, user){
        utils.saveState(auth.state, "user", user)
        router.push('/')
        state.cacheMsg = "";
    },
    // AUTH_LOGOUT(state){
    //     utils.removeState(state, "user");
    //     if(router.fullPath !== "/login") router.push('/login');
    //     state.cacheMsg = "";
    // }
}





export default {
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations
}