<template>
<div class="navbar-fixed" style="user-select: none">
    <nav class="grey darken-3">
        <div class="nav-wrapper">



          <div class="left row" >
            <img src="/icon.png" class="col " style="max-height: 3em;  margin-left: 10px; margin-top: 0.5em" alt="Syrian Arabic Dictionary">
            <a href="#" class="brand-logo col hide-on-small-and-down truncate"  @click="routeTo('/')">
              Syrian Arabic Dictionary
            </a>
            <a href="#" class="brand-logo col hide-on-med-and-up truncate" >
              <small>Syrian Dictionary</small>
            </a>
          </div>



          <ul class="right hide-on-med-and-down">

            <li>
              <div v-if="thisUser" class="left col"> {{ thisUser.displayName }} |</div>
            </li>
            <li>
              <router-link v-if="!thisUser" to="/login">Log in</router-link>
            </li>
            <li>
              <router-link v-if="thisUser" to="/translate">Translate</router-link>
            </li>
            <li>
              <Dropdown v-if="thisUser" id="ProfileDropdown" text="Profile" class="">
                <ul>
                  <li><router-link to="/profile" class="black-text">View profile</router-link></li>
                  <li><a href="#"  class="red-text text-darken-4" @click="logout" >Log out</a></li>
                </ul>
              </Dropdown>

            </li>

            <li>
              <img v-if="thisUser" :src="thisUser.photoURL" class="circle" style="max-height: 3em; margin-right: 10px; margin-top: 0.5em" alt="">
            </li>
          </ul>


        </div>
    </nav>
</div>
</template>

<script>
import Dropdown from "./Controls/Dropdown";

export default {
    name: "Navbar",
  components: {Dropdown},
  data() {
    return {
      MenuIsDropped: false,
      user: null,
    }
  },
  computed: {
      thisUser() {
        return this.$store.state.auth.storage.user
      }
  },
  methods: {
    logout(){
      console.log("logging out!")
      this.$store.dispatch("authLogout")
    },
    routeTo(route){
      if(this.$route.fullPath !== route){
        this.$router.push({path: route})

      }
    },
  }
}
</script>
