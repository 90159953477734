import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import store from "../store"
import firebase from "firebase/app";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LogIn'),
    beforeEnter: isUnauthorized
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/Signup'),
    beforeEnter: isUnauthorized
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile'),
    beforeEnter: isAuthorized
  },
  {
    path: '/translate',
    name: 'Translate',
    component: () => import('../views/Translate'),
    beforeEnter: isAuthorized
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router


function isUnauthorized(to, from, next){
  if(firebase.auth().currentUser){
    next(false);
  }
  else{
    next();
  }
}



function isAuthorized(to, from, next){
  if(window.localStorage.getItem("user")){
    next();
  }
  else{
    next({name: 'Login'});
  }
}