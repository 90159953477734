<template>
  <div id="app"
       style="min-height: 100vh; min-width: 90vw ; ">
    <Navbar/>
    <Loadingbar/>
    <router-view style="height: calc(100vh - 5em); " />
  </div>
</template>


<script>
import Navbar from './components/Navbar'
import Loadingbar from "./components/Loadingbar";

export default {
  name: "App",
  components: {Navbar, Loadingbar}
}
</script>



<style lang="scss">
body {
  background-color: rgb(224, 224, 224);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
