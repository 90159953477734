import axios from 'axios'
import store from '../index'

// globals
// const api = 'https://levantine.xyz/api/'
const api = 'http://192.168.2.114:5000/api/'
const magic = 'mustafaisthebest/'


const state = {
    translatedWords: [],
    suggestedWords: [],
    conjugation: {},
    selectedWord: {},
}

const getters = {

}

const actions = {
    async translateWordToSyrian({commit}, word){

        commit("SET_LOADING", true)
        const response = await axios.get(api + magic + 'translate/en/sy/' + word.toLowerCase())
        commit("UPDATE_TRANSLATED_WORDS", response.data)
        if (response.data && response.data.length){
            commit("UPDATE_SUGGESTED_WORDS", response.data)
            await store.dispatch('selectSyrianWord', {word: response.data[0], translate: false})
        }

        commit("SET_LOADING", false)
    },
    async lookUpSyrian({commit}, word){
        commit("SET_LOADING", true)
        commit("UPDATE_TRANSLATED_WORDS", [])

        const response = await axios.get(api + magic + 'sy/lookup/' + word)
        commit("UPDATE_SUGGESTED_WORDS", response.data)
        // update conjugation and selected word data

        await store.dispatch('selectSyrianWord', {word: response.data[0], translate: true})

        commit("SET_LOADING", false)
    },
    async conjugateSyrian({commit}, wordId){
        commit("SET_LOADING", true)
        const response = await axios.get(api + magic + 'sy/conjugation/' + wordId)
        commit("UPDATE_CONJUGATION", response.data)
        console.log(response.data)
        commit("SET_LOADING", false)
    },
    async selectSyrianWord({commit}, {word, translate=true}){
        // update conjugation and selected word data
        commit('SET_SELECTED_WORD', word)
        // update conjugation and selected word data
        if (['verb', 'noun'].includes(word.type))
            await store.dispatch('conjugateSyrian', word.id)
        else
            commit("UPDATE_CONJUGATION", [])
        // get translated words (POST because no body can be sent with GET)
        if(translate){
            const englishWordsTranslation = await axios.post(api + magic + 'en/getwordsbyid/' , {ids: word.translations})
            commit("UPDATE_TRANSLATED_WORDS", englishWordsTranslation.data.result)
        }

    }
}



const mutations = {
    // suggestWords
    UPDATE_SUGGESTED_WORDS(state, words){
        state.suggestedWords = words;
    },

    // update translated words
    UPDATE_TRANSLATED_WORDS(state, words){
        state.translatedWords = words;
    },
    UPDATE_CONJUGATION(state, conjugation) {
        state.conjugation = conjugation
    },
    SET_SELECTED_WORD(state, word){
        state.selectedWord = word;
    }
}






export default {
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations
}




