<template>
  <div v-if="isLoading" class="progress transparent" style="margin: 0; position: absolute">
    <div class="indeterminate grey darken-4"></div>
  </div>
</template>

<script>
export default {
  name: "Loadingbar",
  computed: {
    isLoading() {
      return this.$store.state.general.isLoading
    }
  }
}
</script>

<style scoped>

</style>