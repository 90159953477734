import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import authGoogle from "./modules/authGoogle";
import userProfile from "./modules/userProfile";
import translate from "./modules/translate";
import general from "./modules/general";


// load Vuex
Vue.use(Vuex)


// create store
export default new Vuex.Store({
  modules: {
    auth,
    authGoogle,
    userProfile,
    translate,
    general
  }
});
