

import "firebase/firestore"

const state = {
    isLoading: false
}

const getters = {

}

const actions = {
    startLoading({commit}){
        commit("SET_LOADING", true)
    },
    finishLoading({commit}){
        commit("SET_LOADING", false)
    },
}



const mutations = {
    SET_LOADING(state, isLoading){
        state.isLoading = isLoading;
    }
}






export default {
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations
}