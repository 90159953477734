import router from "@router";
import firebase from "firebase/app";
import utils from "./utils"
import store from "../index"
import "firebase/firestore"

const state = {
    cacheMsg: {
        error: false,
        msg: ""
    },
    storage: {
        user: utils.getState("user"),
    },
    user: null
}

const getters = {

}

const actions = {
    async authLogin({commit}, {email, password}){
        commit('AUTH_MSG', {msg: "", error: false})

        // commit('AUTH_LOGIN', "email: " + email + "\nPassword: " + password)

        // make sure Email is well formatted
        if( utils.validateEmail(email)){
            await store.dispatch("startLoading")
          firebase.auth().signInWithEmailAndPassword(email, password)
              .then((userCredential) => {
                  commit('AUTH_LOGIN', userCredential.user);
                  store.dispatch("finishLoading")
              })
              .catch((error => {
                  commit('AUTH_MSG', {msg:error.code, error: true})
                  store.dispatch("finishLoading")
              }))
        }
        else
        {
            commit('AUTH_MSG', {msg: "Invalid Email!", error: true})
        }



    },
    async authSignup({commit}, {email, password, firstname, lastname, age, sex}){
        commit('AUTH_MSG', {msg: "", error: false})

        if(email === '' || password === '' || firstname === '' || lastname === ''){
            commit('AUTH_MSG', {msg:"Please fill in the fields first!", error: true})
        }
        else if(email.length < 8 || password.length < 8){
            commit('AUTH_MSG', {msg:"Please make sure your email and password are at least 8 letters long!", error: true})
        }
        else if(firstname.length < 3 || lastname.length < 3){
            commit('AUTH_MSG', {msg:"Please make sure your firstname and lastname are at least three letters long!", error: true})
        }
        else if(!utils.validateEmail(email)){
            commit('AUTH_MSG', {msg:"Email is invalid!", error: true})
        }
        else {
            // Create an account
            firebase.auth().createUserWithEmailAndPassword(email, password)
                .then(async (userCredential) => {
                    // User created successfully!
                    await userCredential.user.updateProfile({
                        // update user profile (DisplayName)
                        displayName: firstname + " " + lastname,
                    })
                        // now update user private data
                        .then(async  () =>  {
                        await firebase.firestore().collection("users").doc(userCredential.user.uid).set({
                            firstname: firstname,
                            lastname: lastname,
                            email: email,
                            birthYear: (new Date()).getFullYear() - age,
                            sex: sex,
                            isVerified: false,
                            profileCreatedOn: Date.now(),
                        })
                            .then(() => {
                                // Private Data created successfully
                                commit('AUTH_LOGIN', userCredential.user)
                                userCredential.user.sendEmailVerification()
                        })
                            .catch(async () => {
                                await userCredential.user.delete()
                            })
                    })

                })
                .catch((error) => {
                    commit('AUTH_MSG', {msg:error.code, error: true})
            })
        }
        console.log(age, sex)
    },
    async authLogout({commit}){
        await firebase.auth().signOut()
            .then(()=> {
                commit("AUTH_LOGOUT")
                console.log("logged out successfully")
            })
            .catch((error) => {
                commit('AUTH_MSG', {msg:error.code, error: true})
                console.log(error)
            })
    },


    authRefresh({commit}){
        commit("AUTH_REFRESH");
    },

    async TestingAction() {
        if (firebase.auth().currentUser) {
            await firebase.auth().currentUser.sendEmailVerification()
        }
    }
}



const mutations = {
    AUTH_MSG(state, msg){
        console.log(msg)
        state.cacheMsg = msg;
    },
    AUTH_REFRESH(state){
        state.cacheMsg.msg = "";
    },
    AUTH_LOGIN(state, user){
        utils.saveState(state, "user", user)
        router.push('/')
        state.cacheMsg.msg = "";
    },
    async AUTH_LOGOUT(state){
        utils.removeState(state, "user")
        utils.removeState(state, "profile")
        if(router.fullPath !== "/login") router.push('/login');
        state.cacheMsg.msg = "";
    },
    AUTH_UPDATE_USER(state, user){
        utils.saveState(state, "user", user)
    }
}





export default {
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations
}